/* General Styles */
.App {
  background-image: url('/home/ubuntu/healseq/public/images/bg.png');
  background-color: #f8f9fa;
  font-family: 'Arial', sans-serif;
  padding: 0;
  margin: 0;
  min-height: 100%;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
}

.Navbar {
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.8), rgba(161, 194, 240, 0.929));
  backdrop-filter: blur(10px);
  padding: 30px; /* Increased padding */
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1);
  position: sticky;
  top: 0;
  z-index: 1000;  
  transition: background-color 0.3s ease, transform 0.3s ease;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Navbar-links {
  display: flex;
  justify-content: flex-end;
  flex-grow: 1;
}

.Navbar-links a {
  margin-left: 25px; /* Increased margin */
  text-decoration: none;
  color: #4a4a4a;
  font-weight: bold;
  letter-spacing: 1.5px; /* Slightly more letter spacing */
  transition: color 0.3s ease, transform 0.3s ease;
  cursor: pointer;
  position: relative;
  font-size: 18px; /* Increased font size */
}

.Navbar-links a::after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 3px;
  bottom: 0;
  left: 0;
  background-color: #3498db;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.Navbar-links a:hover {
  color: #3498db;
}

.Navbar-links a:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.Navbar-collapse-btn {
  display: none;
  cursor: pointer;
  padding: 15px; /* Increased padding */
  transition: transform 0.3s;
}

.logo-container {
  display: flex;
  align-items: center;
}

.logo {
  height: 60px; /* Increased logo height */
  margin-right: 10px; /* Increased margin */
}

.name-subtext {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.name {
  font-size: 30px; /* Increased font size */
  font-weight: bold;
  color: #4a4a4a;
}

.subtext {
  font-size: 18px; /* Increased font size */
  color: #4a4a4a;
}

@media (max-width: 768px) {
  .Navbar-links {
    display: none;
  }

  .Navbar-collapse-btn {
    display: block;
  }

  .name {
    font-size: 24px; /* Adjusted for smaller screens */
  }

  .subtext {
    font-size: 16px; /* Adjusted for smaller screens */
  }
}

canvas {
  position: fixed;
  top: 0;
  left: 0;
}

section {
  padding: 1.5rem; 
  font-size: 1.5rem; 
  /* line-height: 1.6;  */
  margin-bottom: var(--spacing);
}

blockquote {
  margin: 0;
  padding: 0;
  margin-bottom: var(--spacing);
}

blockquote p {
  color: black;
  background-color: white;
  font-size: 4.5rem; /* Increased font size */
  display: inline;
  line-height: 1;
}

.left {
  grid-column: 6 / 12;
}

.Section h2 {
  color: #333;
}

header {
  background-color: rgba(245, 245, 245, 0.9);
  padding: 5rem; /* Increased padding */
  margin-bottom: var(--spacing);
  position: relative;
}

header::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
}

.name-bottom-left {
  position: absolute;
  bottom: 30px; /* Increased bottom margin */
  left: 30px; /* Increased left margin */
  font-size: 5rem; 
  color: #4a4a4a;
  font-family: Cochin;
  white-space: nowrap; 
}

.animate-right,
.animate-left {
  display: inline-block;
  opacity: 0;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

.animate-right {
  animation-name: slideFromRight;
}

.animate-left {
  animation-name: slideFromLeft;
}

@keyframes slideFromRight {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideFromLeft {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@media (max-width: 768px) {
  .name-bottom-left {
    font-size: 4rem; /* Adjusted for smaller screens */
    bottom: 20px; /* Adjusted bottom margin */
    left: 20px; /* Adjusted left margin */
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.Navbar-collapse-btn {
  display: none;
  cursor: pointer;
  padding: 15px;
  background: none;
  border: none;
  flex-direction: column;
  justify-content: space-between;
  height: 24px; /* Adjust the height to fit the bars */
}

.Navbar-collapse-btn .bar {
  width: 25px; /* Set the width of the bars */
  height: 3px;  /* Set the height of the bars */
  background-color: #4a4a4a; /* Set the color of the bars */
  border-radius: 2px; /* Add slight rounding for better appearance */
  transition: all 0.3s ease;
}

@media (max-width: 768px) {
  .Navbar-collapse-btn {
    display: flex;
  }
}

.show-mobile {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 100%;
  right: 0;
  background: linear-gradient(135deg, #f8f9fa, #e9ecef);
  width: 80%; /* Smaller width for a compact design */
  z-index: 999;
  padding: 10px; /* Reduced padding */
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
}

.show-mobile a {
  color: #343a40;
  text-decoration: none;
  padding: 8px 0; /* Smaller padding */
  font-size: 14px; /* Slightly smaller font */
  font-weight: 500;
  border-bottom: 1px solid #dee2e6;
}

.show-mobile a:last-child {
  border-bottom: none; /* Remove the border for the last link */
}

.show-mobile a:hover {
  color: #007bff;
}

@media (max-width: 768px) {
  .Navbar-links {
    display: none;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }

  .show-mobile {
    display: flex;
  }
}


.video-container {
  position: relative;
  max-height: 78vh; /* Use full viewport height or adjust as needed */ 
  overflow: hidden;
}

.background-video {
  width: 100%;
  height: 100%; /* Fill the container height */
  object-fit: cover; /* Ensure the video covers the container without distortion */
}


.parallax {
 
  background-image: url('/home/ubuntu/healseq/public/images/de.jpeg');

  /* Set a specific height */
  height: 200px; 

  /* Create the parallax effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

   /* Ensure the content inside the parallax section is readable */
   display: flex;
   justify-content: center;
   align-items: center;
   color: white; /* Adjust text color as needed */
   text-align: center;
   font-size: 2rem; /* Adjust text size as needed */
}
 
.parallax-content {
  padding: 20px;
  background: rgba(0, 0, 0, 0.5); 
  border-radius: 10px;
}

.parallax2 {
 
  background-image: url('/home/ubuntu/healseq/public/images/Untitled2.png');

  /* Set a specific height */
  height: 250px; 

  /* Create the parallax effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;


   display: flex;
   justify-content: center;
   align-items: center;
   color: white; /* Adjust text color as needed */
   text-align: center;
   font-size: 2rem; /* Adjust text size as needed */
}


.parallax3 {
 
  background-image: url('/home/ubuntu/healseq/public/images/de2.jpeg');
  /* height: 25px;  */

  /* Create the parallax effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
   display: flex;
   justify-content: center;
   align-items: center;
   color: white; 
   text-align: center;
   font-size: 2rem; 
}
 
.parallax-content {
  padding: 20px;
  background: rgba(0, 0, 0, 0.5); 
  border-radius: 10px;
}


/* Updated About Section */
.Section.About {
  opacity: 0;
  transform: translateY(50px);
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.8), rgba(220, 220, 253, 0.8));
  transition: opacity 1s ease-out, transform 1s ease-out;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Flex container for image and text */
.about-content {
  display: flex;
  align-items: center; /* Center items vertically */
  justify-content: center;
  gap: 45px;
  width: 100%;
  max-width: 1500px;
  flex-wrap: wrap; /* Allow wrapping on smaller screens */
  margin-top: 20px;
  overflow: hidden;
}

/* About Image */
.about-image {
  flex: 1;
  min-width: 600px;
  max-width: 600px;
}

.about-image img {
  width: 100%;
  height: auto;
  border-radius: 10px;
}

.about-text {
  flex: 2;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: flex-start;
}

@keyframes slideInLeft {
  from {
    transform: translateX(-50%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInRight {
  from {
    transform: translateX(50%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.about-paragraph {
  font-size: 1.20rem;
  line-height: 1.8;
  background: linear-gradient(135deg, rgba(212, 235, 245, 0.3), rgba(99, 179, 240, 0.884));
  padding: 15px;
  border-left: 5px solid #004d99;
  border-radius: 8px;
  opacity: 0;
  transition: opacity 0.8s ease-out;
}

.animate-left {
  animation: slideInLeft 1.8s ease-out forwards;
}

.animate-right {
  animation: slideInRight 1.8s ease-out forwards;
}

.Section.About.animate {
  opacity: 1;
  transform: translateY(0);
}

.cards-container {
  display: flex;
  justify-content: center;
  gap: 30px;
  flex-wrap: wrap;
  margin-top: 20px;
}

.card {
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid rgba(255, 255, 255, 0.2);
  background: rgba(232, 228, 228, 0.1);
  backdrop-filter: blur(10px);
  border-radius: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 650px;
  margin: 45px;
}

.card-image {
  width: 100px;
  height: auto;
  margin-right: 20px;
}

.card-content {
  flex: 1;
  text-align: left;
}

.card-content h3 {
  margin-top: 0;
}

.card-content p {
  margin: 0;
}

/* Portfolio Section */
.Section.Portfolio {
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 1s ease-out, transform 1s ease-out;
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.8), rgba(196, 222, 236, 0.8));
  text-align: center;
}

.Section.Portfolio.animate {
  opacity: 1;
  transform: translateY(0);
}

.Portfolio .cards-container {
  display: flex;
  justify-content: center;
  gap: 20px; /* Adjusted gap for spacing between cards */
  flex-wrap: wrap;
  margin-top: 20px; /* Adjusted margin for top spacing */
}

.Portfolio .card {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid rgba(255, 255, 255, 0.112);
  background: rgba(87, 157, 222, 0.025);
  backdrop-filter: blur(6px); /* Slightly reduced blur */
  border-radius: 10px; /* Slightly reduced rounded corners */
  padding: 8px; /* Slightly reduced padding for text space */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Slightly reduced shadow */
  max-width: 325px; /* Reduced max width for narrower cards */
  margin: 8px; /* Adjusted margin for spacing */
  text-align: center; /* Centered text alignment */
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Added transition for hover effect */
}

.Portfolio .card:hover {
  transform: translateY(-5px); /* Lift effect on hover */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15); /* Increased shadow on hover */
}

.Portfolio .card-image {
  width: 75%; /* Full width for card image */
  height: auto;
  border-radius: 10px; /* Slightly reduced rounded corners */
  margin-bottom: 10px;
}

.Portfolio .card-content h3 {
  margin-top: 0;
  text-align: center; /* Ensured text alignment is center */
  font-size: 1.2rem; 
}

.Portfolio .card-content p {
  margin: 0;
  text-align: center; /* Ensured text alignment is center */
  font-size: 1.0rem; 
}

/* service cards */
.Section.services {
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.8), rgba(220, 220, 253, 0.8));
  padding: 40px 20px;
  text-align: center;
}


.Section.services.animate {
  opacity: 1;
  transform: translateY(0);
}

.services-cards-container {
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
  margin-top: 20px;
  
  
}

.service-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
  padding: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 350px;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.service-card img {
  width: 55%;
  height: auto;
  margin-bottom: 10px;
}


.service-card h4 {
  margin: 10px 0;
  font-size: 18px;
  text-align: center;
}

.service-card p {
  margin: 0;
  font-size: 16px;
  text-align: center;
}

.service-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}



/* Process Section */
.Section.process {
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.9), rgba(193, 193, 237, 0.9));
  padding: 40px 20px;
  text-align: center;
}

.chart-container {
  width: 95%;
  max-width: 1600px;
  margin: 40px auto;
  border-radius: 10px;
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
  overflow: hidden;
}
.chart-header {
  display: grid;
  grid-template-columns: 2fr repeat(5, 1fr);
  background-color: #444;
  color: #fff;
  padding: 15px;
  font-weight: bold;
  font-size: 0.785em; /* This sets the font size to 14px if the base font size is 16px */
}

.chart-header > div {
  text-align: center;
}
.chart {
  padding: 20px;
}
.chart-row {
  display: grid;
  grid-template-columns: 2fr repeat(5, 1fr);
  align-items: center;
  margin-bottom: 15px;
}
.label {
  padding-right: 20px;
  font-weight: bold;
  color: #444;
  text-align: center;
}
.bar-container {
  position: relative;
  display: flex;
  height: 35px;
  /* background-color: #e8e8e8; */
  border-radius: 5px;
  overflow: hidden;
  /* box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1); */
  grid-column: span 5;
}
.bar {
  background: linear-gradient(90deg, #7287c6, #5899da);
  transition: width 1s ease-in-out;
  height: 100%;
  width: 0;
}

.bar:hover::after {
  opacity: 1;
}

@media (max-width: 768px) {
  .chart-header {
      grid-template-columns: 1fr;
      text-align: center;
  }
  .chart-header > div {
      border-right: none;
      padding: 10px 0;
      border-bottom: 1px solid #ccc;
  }
  .chart-header > div:last-child {
      border-bottom: none;
  }
  .chart-row {
      grid-template-columns: 1fr;
      text-align: center;
  }
  .label {
      padding: 10px 0;
  }
  .bar-container {
      margin-top: 10px;
      grid-column: 1;
  }
}

@media (max-width: 480px) {
  .chart-container {
      width: 100%;
      padding: 10px;
  }
  .chart-header, .chart-row {
      padding: 10px;
  }
  .label {
      padding-right: 0;
      font-size: 14px;
  }
}

/* collab */
.Section.collab {
  text-align: center;
  padding: 50px 20px;
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.8), rgba(196, 196, 236, 0.8));  
}


@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.Section {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.Section.fade-in {
  animation: fadeInUp 1s forwards;
}


.collab p {
  font-size: 1.2em;
  margin-bottom: 40px;
  color: #555;
}

.collab-images-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  position: relative;
  perspective: 1000px;
}

.collab-image {
  background: linear-gradient(135deg, rgba(246, 243, 243, 0.8), rgba(181, 217, 239, 0.8)); 
  width: 200px;
  height: 200px;
  object-fit: cover;
  border-radius: 15px;
  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.collab-image:hover {
  transform: scale(1.1) rotate(0deg) translateX(0) translateY(0);
  opacity: 1;
}

.collab-image:nth-child(1):hover,
.collab-image:nth-child(3):hover {
  opacity: 0.8;
}

.collab-image:nth-child(2):hover,
.collab-image:nth-child(4):hover {
  opacity: 0.9;
}

/* footer */
.footer {
  background-color: #f8f8f8;
  padding: 20px 0;
  color: #333;
  font-size: 15px;
}

.footer-container {
  display: flex;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;
}

.footer-left, .footer-right {
  flex: 1;
}

.footer-left {
  text-align: left;
}

.footer-right {
  text-align: right;
}

.footer-left h2 {
  font-size: 24px;
  color: #007bff;
}

.footer-left p, .footer-right p {
  margin-bottom: 10px;
}

.footer-right p {
  line-height: 1.5;
}

.footer-bottom {
  margin-top: 20px;
  text-align: center;
}

.scroll-top-btn {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  display: none;
  transition: opacity 0.3s;
}

.scroll-top-btn.show {
  display: block;
}

.scroll-top-btn span {
  font-size: 20px;
  vertical-align: middle;
}


/* footer */
#myBtn {
  background-image: url('/home/ubuntu/healseq/public/download.gif');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 30px; 
  height: 30px;
  border: none;
  cursor: pointer;
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000; 
  opacity: 0.7; 
  transition: opacity 0.3s ease-in-out; 
}

#myBtn:hover {
  transform: translateY(-3px); 
  transition: transform 0.3s;
}

